import DOMPurify from 'dompurify';
import axios from 'axios';
import { CONFIG } from '../environments';
import xssPrevent from './xss-prevent';
import locomotiveScrollHelper from './locomotiveScrollHelper';
import { DEALER_ACTIONS_ACTION_CATEGORY, FORM_SUBMIT_ACTION_CATEGORY, GLOBAL_NAV_CATEGORY, UI_CONTROLS_ACTION_CATEGORY } from '../constants';

// export xssPrevent like a module
export const {
  handleUrlXss,
  getPathNameAndSearchFromUrl,
} = xssPrevent;

export const {
  preventCustomScrollPage,
} = locomotiveScrollHelper;

export const getPosition = (element) => {
  let xPosition = 0;
  let yPosition = 0;
  let tmpElm = element;

  while (tmpElm || $(tmpElm).hasClass('.locomotive-scroll')) {
    xPosition += tmpElm.offsetLeft - tmpElm.scrollLeft + tmpElm.clientLeft;
    yPosition += tmpElm.offsetTop - tmpElm.scrollTop + tmpElm.clientTop;
    tmpElm = tmpElm.offsetParent;
  }

  return { x: xPosition, y: yPosition };
};
export const getYoutubeVideoId = (url) => {
  const regExp = /(?:\/embed\/|\/v\/|\/watch\?v=|youtu\.be\/|\/shorts\/)([a-zA-Z0-9_-]+)/;
  const match = url.match(regExp);

  return match && match[1].length === 11 ? match[1] : false;
};

export const getVimeoVideoId = (url) => {
  const regExp = /https?:\/\/(?:www\.)?(?:player\.)?vimeo(?:\.com|pro)?\/(?:[^\d]+)?(\d+)(?:.*)?/i;
  const match = url.match(regExp);

  if (match && match[1]) {
    return match[1];
  }
  return null;
};

export const getKalturaVideoId = (url) => {
  const regExp = /kaltura\.com\/([0-9a-zA-Z_]+)/;
  const match = url.match(regExp);

  if (match && match[1]) {
    return match[1];
  }
  return null;
};

export const isDesktop = () => $(window).outerWidth() > 1199.5;
export const isTablet = () => $(window).outerWidth() > 767 && $(window).outerWidth() < 1200;
export const isTabletHorizontal = () => $(window).outerWidth() > 991.9;

export const isLandScape = () => window.innerWidth > window.innerHeight;

export const timingDelay = (duration, callbackFn) => {
  let start;
  const animStep = (timestamp) => {
    if (!start) {
      start = timestamp;
    }
    const time = timestamp - start;
    if (time < duration) {
      window.requestAnimationFrame(animStep);
    } else {
      callbackFn();
    }
  };
  window.requestAnimationFrame(animStep);
};

export const easeInOutQuad = (x) => x < 0.5 ? 2 * x * x : 1 - ((-2 * x + 2) ** 2) / 2;

export const translate3d = (x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`;

export const uuidv4 = () => `${'xxxx'.replace(/x/g, () => Math.floor((Math.random() * 10e9)).toString(20))}`;

export const shortRandomString = (length = 7) => Math.random().toString(36).substring(2, length + 2);

export const toSlug = (str = '') => str.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

export const cloneData = (data = {}) => JSON.parse(JSON.stringify(data));

//set params to url
/**/
export const setUrlParams = (params) => {
  const urlParams = new URLSearchParams(window.location.search);
  Object.keys(params).forEach((key) => {
    urlParams.set(key, params[key]);
  });
  const url = urlParams.toString() ? `${window.location.pathname}?${decodeURIComponent(urlParams.toString())}` : window.location.pathname;
  window.history.replaceState({}, '', url);
};

// //get params from url
export const getUrlParams = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const getParamsUrl = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

// remove params from url
export const removeUrlParams = (params) => {
  const urlParams = new URLSearchParams(window.location.search);
  if (Array.isArray(params)) {
    params.forEach((param) => {
      urlParams.delete(param);
    });
  } else {
    urlParams.delete(params);
  }
  const url = urlParams.toString() ? `${window.location.pathname}?${urlParams}` : window.location.pathname;
  window.history.replaceState({}, '', url);
};

export const capitalize = (str = '') => str.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const validateZipCode = (val) => {
  const regEx = /^\d{5}$/;
  return regEx.test(val);
};
export const restrictZipCodeInput = (key) => {
  const allowedKeys = [
    'Backspace', 'Tab', 'Enter', 'Escape', 'Delete',
    'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
    'Home', 'End', 'Clear', 'Copy', 'Paste',
  ];

  // Allow number keys and allowed control keys
  return !/^[0-9]$/.test(key) && !allowedKeys.includes(key);
};

export const validateEmail = (email) => {
  const regEx = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  return regEx.test(email);
};

export const validatePhone = (val) => {
  const regEx = /^\d{10}$/;
  return regEx.test(val);
};

export const isValidNumber = (val) => {
  const regEx = /^\d+$/;
  return regEx.test(val);
};

/**
 * Format number to locale string
 * @param {number} number
 */
export const formatNumberWithComma = (number) => {
  const numArray = `${Number(number).toFixed(2)}`.split('.');
  if (numArray.length > 1) {
    const integer = numArray[0];
    const decimal = numArray[1];
    return (Number(integer).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })).concat(`.${decimal.slice(0, 2)}`);
  }

  return Number(number).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
};

const getElementYOfSmoothScroll = (element, scrollElement) => {
  let elementY = element ? getPosition(element).y : 0;
  if (scrollElement && element) {
    elementY = element.getBoundingClientRect().top;
  }
  return elementY;
};
export const smoothScroll = (options) => {
  const { element, scrollElement, duration, adjustSpace, callbackFn } = options;

  // set starting position of scroll is current scroll position
  const startingY = scrollElement ? scrollElement.scrollTop : window.pageYOffset;
  let elementY = getElementYOfSmoothScroll(element, scrollElement);
  let start;

  const step = (timestamp) => {
    if (!start) {
      start = timestamp;
    }
    const time = timestamp - start;
    const percent = Math.min(time / duration, 1);

    /* get Position of element with origin scroll area, if no use scroll body library => let's use window.scrollTop */
    elementY = getElementYOfSmoothScroll(element, scrollElement);
    let adjustSpaceCustom = 0;

    if ($(element).hasClass('wrap-model')) {
      if (!$('body').hasClass('wheel-up')) {
        adjustSpaceCustom = 100;
      } else {
        adjustSpaceCustom = 166;
      }
    }
    const diff = (scrollElement ? elementY - scrollElement.getBoundingClientRect().top : elementY - startingY) + (adjustSpace || 0);
    if (scrollElement) {
      scrollElement.scrollTo(0, scrollElement.scrollTop + diff * easeInOutQuad(percent) - adjustSpaceCustom);
    } else {
      window.scrollTo(0, startingY + diff * easeInOutQuad(percent) - adjustSpaceCustom);
    }

    if (time < duration) {
      window.requestAnimationFrame(step);
    } else if (callbackFn !== null && typeof callbackFn === 'function') {
      callbackFn();
    } else {
      // nothing to do
    }
  };
  if (element) {
    window.requestAnimationFrame(step);
  }
};

export const getQueryParams = (url) => {
  const queryParams = new URLSearchParams(url || window.location.search);
  const paramObj = {};
  Array.from(queryParams.keys()).forEach((key) => {
    paramObj[key] = queryParams.get(key);
  });
  return paramObj;
};

// parse string to DOM string
export const parseDomFromString = (string = '') => {
  // check string is string & not empty
  const text = typeof string === 'string' && string.trim() !== '' ? string : '';
  // replace special character <, > to &lt;, &gt;
  let cleanString = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  cleanString = new DOMParser().parseFromString(cleanString, 'text/html').body.textContent || '';

  return DOMPurify.sanitize(cleanString);
};

export const hasSpecificHtmlContent = (scriptContent) => {
  // Regular expression to match specific HTML tags (e.g., p, span, a, div, h1, h2, h3, ul, li, img, strong, em)
  const specificHtmlTagRegex = /<(p|span|a|div|h1|h2|h3|ul|li|img|strong|em)[^>]*>.*?<\/(p|span|a|div|h1|h2|h3|ul|li|img|strong|em)>/;
  // Test if the script content contains any of the specified HTML tags
  return specificHtmlTagRegex.test(scriptContent);
};

/* generate Image For src using on dev  */
export const generateImage = (src = '-/media/products/accessories/placeholder/overview-thumbnail.jpg') => src && typeof src === 'string' ? `${CONFIG.ENDPOINT_IMAGE || CONFIG.ENDPOINT}${src.startsWith('/') ? src.substring(1) : src}` : '';

export const getFullUrlWithDomain = (url) => {
  if (!url) {
    return '';
  }

  if (url.startsWith('http')) {
    return url;
  }

  if (url.startsWith('/')) {
    return `${window.location.origin}${url}`;
  }

  return `${window.location.origin}/${url}`;
};

// separate time from am/pm, example: 4:30am => 4:30 am
export const separateTime = (time) => time.replace(/(am|pm)/i, ' $1');

export const convertDateTimeFromTimezoneToLocal = (dateTime) => {
  // get timezone with UTC
  const date = new Date(dateTime);
  // return follow format May 25 2024 7:30 am
  const month = date.toLocaleString('en-US', { month: 'long' });
  const hour = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' });
  return {
    month,
    day: date.getDate().toString().padStart(2, '0'),
    year: date.getFullYear(),
    time: hour,
  };
};

// is Exist Function
export const isExistFunction = (func) => func && typeof func === 'function';

// calculate adjust space for scroll to element: header, sub navigation, main content pin data for trim hero banner
const calculateAdjustSpace = (elementTop = 0) => {
  const $header = $('#header');
  const $subNavigation = $('.js-group-content-top');
  const headerHeight = $header.outerHeight() || 0;
  const currentOffsetTop = isDesktop() ? (window.smoothScrollY || 0) : window.scrollY;
  // has sub navigation
  // 48 is height of sub navigation when pinned on top during scroll
  const subNavigationHeight = $subNavigation.length ? 48 : 0;
  // 400px is delta height show/hide of header when fixed on top during scroll
  const deltaHeightHeader = currentOffsetTop - 400 > elementTop ? headerHeight : 0;
  return deltaHeightHeader + subNavigationHeight;
};

export const scrollPageToElement = (element, options = {}) => {
  if (!element) {
    return;
  }

  let startAnchorScrollTimer = null;
  const locoScrollEvent = window.scrollAgent.$scrollEvent;
  const { delay: delayMs = 0, duration = 1000, resetToTop = false, adjustSpace = 0 } = options;

  const elementTop = isDesktop() ? getPosition(element).y : $(element).offset().top;
  const destinationOffsetTop = elementTop === 0 ? elementTop : elementTop - calculateAdjustSpace(elementTop) + adjustSpace;
  // reset to top page when screen is desktop
  if (resetToTop && locoScrollEvent) {
    locoScrollEvent.scrollTo(0, { duration: 0 });
    window.scrollTo(0, 0);
  }
  const scrollFunc = (destination) => {
    window.startCustomScrollToAnchor = true;
    clearTimeout(startAnchorScrollTimer);
    if (isDesktop() && locoScrollEvent) {
      locoScrollEvent.update();
      locoScrollEvent.scrollTo(destination, { duration });
      startAnchorScrollTimer = setTimeout(() => {
        window.startCustomScrollToAnchor = false;
      }, duration + 2000);
    } else {
      $('html, body').animate({ scrollTop: destination }, duration, () => {
        setTimeout(() => {
          window.startCustomScrollToAnchor = false;
        }, 2000);
      });
    }
  };

  if (delayMs) {
    timingDelay(delayMs, () => {
      scrollFunc(destinationOffsetTop);
    });
  } else {
    scrollFunc(destinationOffsetTop);
  }

  document.addEventListener('anyImgLoaded', () => {
    if (!window.startCustomScrollToAnchor) {
      return;
    }

    const reCalcElementTop = isDesktop() ? getPosition(element).y : $(element).offset().top;
    const recalculatedDestinationOffsetTop = reCalcElementTop === 0 ?
      reCalcElementTop : reCalcElementTop - calculateAdjustSpace(reCalcElementTop) + adjustSpace;

    if (recalculatedDestinationOffsetTop !== destinationOffsetTop && isDesktop()) {
      scrollFunc(recalculatedDestinationOffsetTop);
    }
  });
};

export const goBackUrl = (callbackUrl = '') => {
  if (document.referrer?.startsWith(window.location.origin)) {
    window.location.href = handleUrlXss(document.referrer);
  } else if (callbackUrl && typeof callbackUrl === 'string') {
    window.location.href = handleUrlXss(callbackUrl);
  } else {
    window.location.href = window.location.origin;
  }
};

export const deepMerge = (target, source) => {
  Object.keys(source).forEach((key) => {
    if (source[key] instanceof Object) {
      if (!target[key]) {
        Object.assign(target, { [key]: {} });
      }
      deepMerge(target[key], source[key]);
    } else {
      Object.assign(target, { [key]: source[key] });
    }
  });
  return target;
};

export const checkIsWhitelistTargetUrl = (targetUrl) => {
  if (!targetUrl) {
    return true;
  }

  const whitelist = window.HONDA_LEAVING_WHITELIST || [];

  const whitelistArrayText = [
    'javascript:;', // eslint-disable-line
    'javascript:void(0);', // eslint-disable-line
    '#!',
    'tel:',
    'mailto:',
  ];

  const url = new URL(targetUrl, window.location.href);
  const anchorHostname = url.hostname;

  // check targetUrl whitelist not include this url
  const filterWhitelist = whitelist.filter((item) => (targetUrl.indexOf(item) > -1));
  const filterWhitelistArrayText = whitelistArrayText.filter((item) => (targetUrl.indexOf(item) > -1));
  if (
    !filterWhitelist.length
    && !filterWhitelistArrayText.length
    && anchorHostname !== window.location.hostname
  ) {
    return false;
  }

  return true;
};

/**
 * update data layer
 * @param {Object} custom
 */
export const updateDataLayer = (custom = {}) => {
  // update data layer
  if (window.digitalData) {
    window.digitalData = deepMerge(window.digitalData, custom);
  }
};

export const trackingEvent = ({ event, type, label, category, custom = null, isForce = false }) => {
  // eslint-disable-next-line no-underscore-dangle
  if (!window.digitalData) {
    return;
  }

  // eslint-disable-next-line camelcase
  window.digitalData.event_metadata.action_type = type;
  // eslint-disable-next-line camelcase
  window.digitalData.event_metadata.action_label = label;
  // eslint-disable-next-line camelcase
  window.digitalData.event_metadata.action_category = category;
  // eslint-disable-next-line camelcase
  window.digitalData.page.full_url = window.location.href;
  // eslint-disable-next-line camelcase
  window.digitalData.page.page_friendly_url = `${window.location.origin}${window.location.pathname}`;

  if (custom) {
    window.digitalData = deepMerge(window.digitalData, custom);
  }

  // eslint-disable-next-line no-underscore-dangle
  if ((Array.isArray(window.trackingQueues) && typeof window._satellite?.track !== 'function') || !window.OnetrustActiveGroups) {
    window.trackingQueues.push({
      event,
      data: JSON.stringify(window.digitalData),
    });

    return;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (isForce || checkIsWhitelistTargetUrl(window.digitalData.metadata.target_url)) {
    // set target_url when is ui controls event category
    const nonUrlCategory = [
      UI_CONTROLS_ACTION_CATEGORY,
      FORM_SUBMIT_ACTION_CATEGORY,
      DEALER_ACTIONS_ACTION_CATEGORY,
      GLOBAL_NAV_CATEGORY,
    ];
    const whitelistNonLink = [
      'javascript:;', // eslint-disable-line
      'javascript:void(0);', // eslint-disable-line
      '#!',
    ];
    if (nonUrlCategory.includes(category) && (!window.digitalData.metadata.target_url) || whitelistNonLink.includes(window.digitalData.metadata.target_url)) {
      // eslint-disable-next-line camelcase
      window.digitalData.metadata.target_url = label?.toLowerCase();
    } else if (window.digitalData.metadata.target_url && !window.digitalData.metadata.target_url.includes('tel:')) {
      // encode target_url
      // eslint-disable-next-line camelcase
      window.digitalData.metadata.target_url = encodeURI(window.digitalData.metadata.target_url);
    } else {
      // do nothing
    }

    if (typeof window.lowerCaseValues === 'function') {
      window.digitalData = window.lowerCaseValues(window.digitalData);
    }

    // eslint-disable-next-line no-underscore-dangle
    window._satellite.track(event);

    // update data layer
    updateDataLayer({
      // eslint-disable-next-line camelcase
      link_metadata: {
        // eslint-disable-next-line camelcase
        destination_url: '',
      },
      metadata: {
        // eslint-disable-next-line camelcase
        target_url: '',
      },
      // eslint-disable-next-line camelcase
      event_metadata: {
        // eslint-disable-next-line camelcase
        download_title: '',
      },
    });
  }
};

export const trackingControlUI = ($element, label) => {
  const eventTrigger = $element.attr('data-event-trigger-control') || '';
  const eventType = $element.attr('data-event-type') || '';
  const eventCategory = $element.attr('data-event-category') || '';
  const eventModule = $element.attr('data-event-module') || '';

  if (eventTrigger) {
    trackingEvent({
      event: eventTrigger,
      type: eventType,
      label: `${window.pageName}:${eventModule}:${label}`,
      category: eventCategory,
    });
  }
};

export const getRecaptchaToken = async (action) => new Promise((resolve) => {
  if (!window.HONDA_RECAPTCHA_SETTINGS?.siteKey) {
    resolve(null);
  }
  window.grecaptcha.ready(() => {
    try {
      window.grecaptcha.execute(window.HONDA_RECAPTCHA_SETTINGS.siteKey, { action }).then((token) => {
        resolve(token);
      });
    } catch (error) {
      resolve(null);
    }
  });
});

export const getCsrfTokenFromHtmlString = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc.querySelector('input[name="__RequestVerificationToken"]')?.value;
};

export const getCsrfTokenFromApi = async () => {
  try {
    if (!window.HONDA_CSRF_SETTINGS?.getTokenUrl || !window.HONDA_CSRF_SETTINGS?.csrfEnabled) {
      return null;
    }
    const { data } = await axios.get(CONFIG.ENDPOINT.concat(window.HONDA_CSRF_SETTINGS?.getTokenUrl));
    return getCsrfTokenFromHtmlString(data?.token);
  } catch (error) {
    console.log(error);
    return null;
  }
};